<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 md3 text-left>
            <span class="mainHeader">DASHBOARD</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm4 md4 pr-4 py-6>
        <v-card class="pa-4 rounded-xl profitlossshadow">
          <v-layout wrap pb-4>
            <v-flex xs12 class="tst2" text-left pl-2>Total Sales</v-flex>
          </v-layout>
          <v-layout wrap pb-2>
            <v-flex xs12 class="dashtile" pl-10>₹ 0.00 </v-flex>
            <v-flex xs12 class="tst2" text-right>Rupees</v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4 md4 pr-4 py-6>
        <v-card class="pa-4 rounded-xl profitlossshadow">
          <v-layout wrap pb-4>
            <v-flex xs12 class="tst2" text-left pl-2>Total Sales</v-flex>
          </v-layout>
          <v-layout wrap pb-2>
            <v-flex xs12 class="dashtile" pl-10> 0.00 gm</v-flex>
            <v-flex xs12 class="tst2" text-right>weight</v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4 md4 pr-4 py-6>
        <v-card class="pa-4 rounded-xl profitlossshadow">
          <v-layout wrap pb-4>
            <v-flex xs12 class="tst2" text-left pl-2>Total Purchase</v-flex>
          </v-layout>
          <v-layout wrap pb-2>
            <v-flex xs12 class="dashtile" pl-10>₹ 0.00</v-flex>
            <v-flex xs12 class="tst2" text-right>Rupees</v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 py-2>
        <v-layout wrap>
          <v-flex xs12 md3 text-left>
            <span class="mainHeader">LATEST ACTIVITIES</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm8 md8 pr-4 pb-5 pb-md-0>
        <v-card class="pa-2 rounded-xl profitlossshadow">
          <v-layout wrap>
            <v-flex xs12 pa-4>
              <div id="chart">
                <apexchart
                  type="area"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 sm4 pr-4>
        <v-card class="pa-2 rounded-xl profitlossshadow">
          <v-layout wrap pa-4>
            <v-flex xs12 class="tst2" text-left py-2>Gold Rate</v-flex>
          </v-layout>
          <v-layout wrap py-4>
            <v-flex xs12 class="dashtile" pl-10
              >₹ 0.00 <span class="subhead4">/gm</span></v-flex
            >
          </v-layout>
          

          <v-layout wrap pa-4>
            <v-flex xs12 class="tst2" text-left py-2>Silver Rate</v-flex>
          </v-layout>
          <v-layout wrap py-4>
            <v-flex xs12 class="dashtile" pl-10
              >₹ 0.00 <span class="subhead4">/gm</span></v-flex
            >
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      username: null,
      name: null,
      address: null,
      phone: null,
      email: null,

      series: [
        {
          name: "Profit",
          data: [31, 40, 28, 51, 42, 109, 100, 30, 50, 78, 89, 80],
        },
        {
          name: "Loss",
          data: [11, 32, 45, 32, 34, 52, 41, 56, 45, 65, 76, 56],
        },
      ],
      chartOptions: {
        colors: ["#731EFF", "#F036DE"],
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",

          categories: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",

            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy ",
          },
        },
      },
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.addAdmin();
      }
    },
    addAdmin() {
      axios({
        url: "/user/jewelleryregister",
        method: "POST",
        data: {
          username: this.username,
          email: this.email,
          jewelleryName: this.name,
          phone: this.phone,
          location: this.address,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.email = null;
            this.phone = null;
            this.name = null;
            this.address = null;
            this.username = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
